"use strict"

 /**
  * A class to handle extra stuff for NetworkPropertySet subclasses
  */
class NPSUtil {
    /**
     * Attaches any network property handlers
     *
     * @param {NetworkPropertySet} p
     */
    static attachNPH(p) {
        for(
            const [i, n] of
            Object.keys(p.networkPropertyHandlers).entries()
        ) {
            Object.defineProperty(p, n, {
                enumerable: false,
                get() {
                    return this._g(i, n)
                },
                set(v) {
                    this._s(i, v)
                },
            })
        }
    }

    /**
     * Returns a promise of the collection length for a URL, if it's supported.
     *
     * @param {string} url
     * @param {{[x: string]: *}} data arguments, if applicable
     * @returns {Promise<number>}
     */
    static async collectionLength(url, data = {}) {
        return $.ajax({
            method: "head",
            url: url,
            data: data,
        }).then(
            (data, textStatus, jqXHR) => {
                const header = jqXHR.getResponseHeader("X-Collection-Length")
                if(header) {
                    return Promise.resolve(+header)
                } else {
                    return Promise.reject()
                }
            }
        )
    }

    /**
     * Returns a function suitable for attachment to the prototype as a method
     * mapping to a JSON-RPC endpoint, eg:
     *
     *  `Foo.prototype.bar = NPSUtil.j(Foo, "bar")`
     *
     * @template {NetworkPropertySet} T
     * @param {{new(...args: any[]): T}} class_object Present only for type checking
     * @param {string} name
     * @param {?{beforeCall?: (this: T) => void | boolean, error?: (this: T, status: number, error: * | null, params: any[]) => *, success?: (this: T, result: *, params: any[]) => *} } [options]
     * @returns {(this: T, ...params: any[]) => Promise<any>}
     */
    static j(
        class_object,
        name,
        options = null
    ) {
        /**
         * @type {(this: T, ...params: any[]) => Promise<any>}
         */
        const f = function(...params) {
            if(options && options.beforeCall) {
                const r = options.beforeCall.call(this)
                if(r === false) {
                    return Promise.reject(r)
                }
            }
            if(!this.networkMethodInfo.path) {
                throw new Error("NPSUtil: No path for JSON-RPC")
            }
            const action = NPSUtil.jsonRPC(
                this.networkMethodInfo.path,
                name,
                ...params
            )
            if(options && options.success) {
                const s = options.success
                if(options.error) {
                    const e = options.error
                    return action.then(
                        result => s.call(this, result),
                        (status, error) => e.call(
                            this,
                            status,
                            error,
                            params
                        )
                    )
                } else {
                    return action.then(
                        result => s.call(this, result, params)
                    )
                }
            } else {
                return action
            }
        }
        return f
    }

    /**
     * Wraps a simple JSON-RPC call.
     *
     * @param {string} url
     * @param {string} method
     * @param {*[]} params
     * @return {Promise<*>} The result hunk. Rejections will include the HTTP
     *  status code and, where possible, the error hunk.
     */
    static async jsonRPC(url, method, ...params) {
        /**
         * @type {{jsonrpc: "2.0", result: *, id: ?string}}
         */
        let response
        try {
            response = await $.ajax({
                method: "post",
                url: url,
                contentType: "application/json",
                data: JSON.stringify(
                    this.jsonRPCCompile(method, ...params)
                ),
            })
        } catch(jqxhr) {
            if(
                jqxhr.responseJSON &&
                jqxhr.responseJSON.error
            ) {
                return $.Deferred().reject(
                    jqxhr.status,
                    jqxhr.responseJSON.error
                )
            } else {
                return $.Deferred().reject(
                    jqxhr.status,
                    null
                )
            }
        }
        return response.result
    }

    /**
     * Compiles the inner data for a JSON-RPC call
     *
     * @param {string} method
     * @param  {*[]} params
     * @returns {{jsonrpc: string, method: string, params: *[], id: null}}
     */
    static jsonRPCCompile(method, ...params) {
        return {
            jsonrpc: "2.0",
            method: method,
            params: params,
            id: null,
        }
    }
    /**
     * Returns an XHR onprogress handler for friendly JSON arrays. Typically you
     * might do:
     *
     * const xhr = new XMLHttpRequest()
     * xhr.onprogress = NPSUtil.streamFriendlyJSONArray(
     *  item => this.items.push(new Foo(item))
     * )
     *
     * xhr.open("GET", `/foo/bar`)
     * xhr.send()
     *
     * This expects a format like '[\n{},\n{}\n]'
     *
     * @param {(item: *) => *} f
     * @returns {(event: ProgressEvent) => void}
     */
    static streamFriendlyJSONArray(f) {
        let offset = 0
        /**
         * @type {(event: ProgressEvent) => void}
         */
        return event => {
            /**
             * @type {XMLHttpRequest}
             */
            //@ts-ignore
            const xhr = event.target
            if(offset < xhr.responseText.length) {
                let buffer = xhr.responseText.substring(offset)
                if(offset == 0) {
                    if(!buffer.startsWith("[")) {
                        throw new Error(
                            `NPSUtil: Couldn't parse JSON, expected "[" at "${buffer.replace(/(.{5}).*/s, "$1...")}"`
                        )
                    }
                    offset++
                    buffer = buffer.substring(1)
                }
                for(const part of buffer.split(/(\n)/)) {
                    let md
                    if(part == "\n") {
                        offset += part.length
                    } else if(part.match(/^\]$/)) {
                        offset += part.length
                    } else if(
                        md = part.match(/^([{].*[}]|\[.*\]|".*"|\d.*\d),?$/)
                    ) {
                        f(JSON.parse(md[1]))
                        offset += part.length
                    }
                }
            }
        }
    }
    /**
     * Returns an XHR onprogress handler for friendly JSON objects. Typically
     * you might do:
     *
     * const xhr = new XMLHttpRequest()
     * xhr.onprogress = NPSUtil.streamFriendlyJSONObject(
     *  (k, v) => this.items[k] = new Foo(v)
     * )
     *
     * xhr.open("GET", `/foo/bar`)
     * xhr.send()
     *
     * This expects a format like '{\n"foo":{},\n"bar":{}\n}'
     *
     * As a special case, this will accept '[\n]', calling it zero times.
     *
     * @param {(k: string, v: *) => *} f
     * @returns {(event: ProgressEvent) => void}
     */
    static streamFriendlyJSONObject(f) {
        let offset = 0
        /**
         * @type {(event: ProgressEvent) => void}
         */
        return event => {
            /**
             * @type {XMLHttpRequest}
             */
            //@ts-ignore
            const xhr = event.target
            if(offset < xhr.responseText.length) {
                let buffer = xhr.responseText.substring(offset)
                if(offset == 0) {
                    if(!buffer.match(/^[[{]/)) {
                        throw new Error(
                            `NPSUtil: Couldn't parse JSON, expected "[" or "{" at "${buffer.replace(/(.{5}).*/s, "$1...")}"`
                        )
                    }
                    offset++
                    buffer = buffer.substring(1)
                }
                for(const part of buffer.split(/(\n)/)) {
                    let md
                    if(part == "\n") {
                        offset += part.length
                    } else if(part.match(/^[\[\],]?$/)) {
                        offset += part.length
                    } else if(
                        md = part.match(/^(".*?"):\s*([{].*[}]|\[.*\]|".*"|\d.*\d),?$/)
                    ) {
                        f(JSON.parse(md[1]), JSON.parse(md[2]))
                        offset += part.length
                    }
                }
            }
        }
    }
}

/**
 * Superclass for objects which fetch some properties from the network.
 * Properties must be exposed in networkPropertyHandlers, and all of such will
 * have an appropriate getter (initially returning null) and a setter (just sets
 * the property). Setting null is a no-op in the constructor, to help you
 * declare the property for compilers.
 *
 * For more info, please see README.md in the submodule
 */
class NetworkPropertySet {
    /**
     * @deprecated
     *
     * Returns a function suitable for attachment to the prototype as a method
     * mapping to a JSON-RPC endpoint, eg:
     *
     *  `Foo.prototype.bar = Foo.m("bar")`
     *
     * @param {string} name
     * @param {(result: *) => *} [success_handler]
     * @param {(status: number, error: * | null) => *} [error_handler]
     * @param {() => void} [before_call]
     * @return {(...args) => Promise<*>}
     */
    static m(
        name,
        success_handler = undefined,
        error_handler = undefined,
        before_call = undefined
    ) {
        return NPSUtil.j(
            this,
            name,
            {
                beforeCall: before_call,
                error: error_handler,
                success: success_handler,
            }
        )
    }

    /**
     * Builds the object. Behaviour is inferred from networkPropertyHandlers.
     */
    constructor() {
        /**
         * @private
         * @type {(?true)[]} These are true for failed loads
         */
        this._f = []
        /**
         * @private
         * @type {(?PromiseLike<*>)[]} These are promises which are resolved on
         * load. They principally feed preload().
         */
        this._l = []
        /**
         * @private
         * @type {(?*)[]} These are the stored result objects.
         */
        this._r = []
        /**
         * @private
         * This is a ticker so that Vue knows to update.
         */
        this._v = 0

        /** @type {NetworkPropertySet} */
        let p
        for(
            p = Object.getPrototypeOf(this);
            p !== NetworkPropertySet.prototype;
            p = Object.getPrototypeOf(p)
        ) {
            if(p.npsMeta && !p.npsMeta.prepared) {
                if(p.hasOwnProperty("networkPropertyHandlers")) {
                    NPSUtil.attachNPH(p)
                }
                p.npsMeta.prepared = true
            }
        }
    }

    get failedProperties() {
        const npns = Object.keys(this.networkPropertyHandlers)
        /**
         * @type {{[property: string]: true}}
         */
        const out = {}
        for(const [i, v] of this._f.entries()) {
            if(v) out[npns[i]] = v
        }
        return out
    }

    /**
     * @protected
     * @type {{path: ?string}}
     *
     * This maps synthesised methods to info used to JSON-RPC them. A simple
     * example would be:
     *
     * return {
     *  path: `/a/b/${this.id}`,
     *  methods: {
     *      foo: result => console.log(result)
     *  }
     * }
     *
     * The above would mean that foo(...p) would call JSON-RPC "foo" on
     * /a/b/${this.id} with params [...p], and then log the result. All of these
     * are naturally async.
     *
     * You can omit methods; if you do, you must add them after the class
     * definition via static method m() above. This enables syntax checking, so
     * in general omitting methods is preferred.
     */
    get networkMethodInfo() {
        return {
            path: null,
        }
    }

    /**
     * @protected
     * @type {{[x: string]: () => (PromiseLike|*)}}
     * This maps synthesised property names to their handlers. A simple example
     * would be:
     *
     * return {
     *      foo: () => $.ajax("/foo"),
     * }
     *
     * A note of caution: this must always return its keys in exactly the same
     * order.
     */
    get networkPropertyHandlers() {
        return {}
    }

    get npsMeta() {
        if(!this.hasOwnProperty("_npsMeta")) {
            this._npsMeta = {
                prepared: false,
            }
        }
        return this._npsMeta
    }

    /**
     * @private
     * Main get handler
     *
     * @param {number} i This is a property-unique number
     * @param {string} name Mainly for debugging
     * @returns {?*}
     */
    _g(i, name) {
        if(this._v == 1000) {
            // This is here to make sure that Vue notices a change!
            console.info("Busy object!")
        }
        if(!this._l[i]) {
            this._l[i] = null
            if(this._r[i] === undefined) this._r[i] = null
            this._f[i] = null
            const v = this.networkPropertyHandlers[name]()
            if(v.then) {
                /** @type {PromiseLike} */
                const promise = v
                const p = promise.then(
                    result => {
                        this._r.splice(i, 1, result)
                        this._v++
                        return result
                    },
                    e => {
                        if(e) {
                            console.error(e)
                        }
                        this._f.splice(i, 1, true)
                        this._v++
                        return Promise.reject(e)
                    }
                )
                this._l.splice(i, 1, p)
            } else {
                this._r.splice(i, 1, v)
                this._v++
                this._l.splice(i, 1, Promise.resolve(v))
            }
        }
        if(this._f[i]) {
            console.warn(
                `Property ${this.constructor.name}#${name} has failed load`
            )
            return null
        } else if(this._r[i] === undefined) {
            return null
        } else {
            return this._r[i]
        }
    }

    /**
     * @private
     * Main set handler
     *
     * @param {number} i This is a property-unique number
     * @param {any | null | undefined} v Values other than null or undefined
     * will be stored as if loaded.
     * @returns {void}
     */
    _s(i, v) {
        if((v !== null && v !== undefined) || this._r.hasOwnProperty(i)) {
            this._l[i] = null
            this._l.splice(i, 1, Promise.resolve(v))
            this._v++
            this._r[i] = null
            this._r.splice(i, 1, v)
        }
    }

    /**
     * Returns a promise of the collection length for a URL, if it's supported.
     *
     * @param {string} url
     * @param {{[x: string]: *}} data arguments, if applicable
     */
    collectionLength(url, data = {}) {
        return NPSUtil.collectionLength(url, data)
    }

    /**
     * @deprecated
     * Wraps a simple JSON-RPC call.
     *
     * @param {string} url
     * @param {string} method
     * @param {*[]} params
     * @return {Promise<*>} The result hunk. Rejections will include the HTTP
     *  status code and, where possible, the error hunk.
     */
    async _jsonRPC(url, method, ...params) {
        return NPSUtil.jsonRPC(url, method, ...params)
    }

    /**
     * Returns the request hunk which calling the named JSON-RPC method would,
     * in principle, use.
     *
     * This is in built-in `Request` format if supported, and `$.ajax()` input
     * format otherwise. It can just be plugged into `window.fetch()` or
     * `$.ajax()` as appropriate.
     *
     * @param {string} method
     * @param  {*[]} params
     * @returns {Request | JQueryAjaxSettings}
     */
    jsonRPCCompileFull(method, ...params) {
        const content = NPSUtil.jsonRPCCompile(method, ...params)
        if(!this.networkMethodInfo.path) {
            throw new Error("NetworkPropertySet: No path for JSON-RPC")
        }
        if(typeof Request !== "undefined") {
            return new Request(
                this.networkMethodInfo.path,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(content),
                }
            )
        } else {
            return {
                method: "post",
                url: this.networkMethodInfo.path,
                contentType: "application/json",
                data: JSON.stringify(content),
            }
        }
    }

    /**
     * Wraps a simple JSON-RPC call in bacon. Send bacon. Or fetch.
     *
     * @param {string} url
     * @param {string} method
     * @param {*[]} params
     * @returns {boolean}
     */
    jsonRPCBeacon(url, method, ...params) {
        if(navigator.sendBeacon) {
            const data = new Blob(
                [JSON.stringify(NPSUtil.jsonRPCCompile(method, ...params))],
                {
                    type: "application/json",
                }
            )
            return navigator.sendBeacon(url, data)
        } else if(typeof fetch !== "undefined") {
            const data = new Blob(
                [JSON.stringify(NPSUtil.jsonRPCCompile(method, ...params))],
                {
                    type: "application/json",
                }
            )
            fetch(
                url,
                {
                    method: "POST",
                    body: data,
                    keepalive: true,
                }
            )
            return true // Speculative
        } else {
            NPSUtil.jsonRPC(url, method, ...params)
            return true
        }
    }

    /**
     * Returns the request hunk which calling the named network method would, in
     * principle, use.
     *
     * @param {string} method
     * @param  {*[]} params
     * @returns {Request | JQueryAjaxSettings}
     */
    networkMethodCompileFull(method, ...params) {
        return this.jsonRPCCompileFull(method, ...params)
    }

    /**
     * Starts the load immediately for the given names. This isn't synchronous,
     * it just immediately starts the load. Suitable for cases where you know
     * you're going to want particular info and don't want a delay later when
     * you fetch it.
     *
     * @param {string[]} names
     * @returns {Promise<*[]>} A promise which will be resolved when everything
     *  is loaded.
     */
    async preload(names) {
        const values = []
        for(const name of names) {
            values.push(await this.singleProp(name))
        }
        return values
    }

    /**
     * Starts the load immediately for the given name.
     *
     * @param {string} name
     * @returns {Promise<*>} A promise which will be resolved when the given
     * thing is loaded
     */
    async preloadSingle(name) {
        const npns = Object.keys(this.networkPropertyHandlers)
        const i = npns.indexOf(name)
        if(i > -1) {
            this._g(i, name)
            return await this._l[i]
        } else {
            return Promise.reject()
        }
    }

    /**
     * This wipes the load state for the given names, meaning that next time
     * they are accessed a new load will be started. This doesn't wipe the
     * value.
     * @param {string[]} names
     */
    reload(names) {
        const npns = Object.keys(this.networkPropertyHandlers)
        names.map(n => npns.indexOf(n)).filter(i => i > -1).forEach(i => {
            delete this._f[i]
            delete this._l[i]
        })
        this._v++
    }

    /**
     * Starts the load immediately for the given name.
     *
     * @param {string} name
     * @returns {Promise<* | null | undefined> | *} A promise which will be
     * resolved when the given thing is loaded, or the value where that is
     * clearly possible. This will not return null or undefined without a
     * wrapping promise, so you can safely just test .then on the response.
     */
    singleProp(name) {
        const npns = Object.keys(this.networkPropertyHandlers)
        const i = npns.indexOf(name)
        if(i > -1) {
            const v = this._g(i, name)
            if(v !== null && v !== undefined) {
                return v
            } else {
                return this._l[i]
            }
        } else {
            throw new Error(
                `NetworkPropertySet: No property "${name}" configured`
            )
        }
    }
}
